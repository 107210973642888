import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Hean = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #6B165A, #A6228B, #B6249A, #A6228B, #6B165A )",
        icons: "#c929aa",
        navClass: "hean",
      }}
      seo={{
        title: "Hean",
        headerTitle: "hean",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/hean_top_back.png"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/hean/",
      }}
    >
      <PortfolioHeader name="hean" height="86" />
      <section className="container-fluid hean_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Hean</h1>
              <ul>
                <li>Projekty opakowań</li>
                <li>Katalogi</li>
                <li>Wizualizacje</li>
                <li>Zdjęcia produktowe</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                We współpracy z firmą Hean zaprojektowaliśmy katalogi produktów
                kosmetycznych. Głównym zadaniem było zaprezentowanie produktów w
                najbardziej atrakcyjny i promujący sprzedaż sposób - było to
                szczególnie ważne ze względu na wysoką konkurencyjność
                produktów. Wykonaliśmy projekty opakowań dla wszystkich
                podkładów, cieni do powiek, pomadek oraz etykiet (Seria Matte
                Touch Fluid, Seria Art Makeup Fluid Series, Seria Make-Up
                Remover, Cream Boutique Series, Hean Basic Care Cream Series.).
                Odpowiadaliśmy również za wykonanie wizualizacji oraz zdjęć
                produktowych. Ponadto zaprojektowaliśmy wiele pomocy
                sprzedażowych, takich jak: ulotki, stojaki do makijażu czy
                stoiska sprzedażowe.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="hean_section_3">
        <img src={require("../../assets/img/portfolio/hean.png")} alt="" />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Hean;
